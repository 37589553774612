body {
  margin: 0;
  font-family: century-gothic, sans-serif;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
