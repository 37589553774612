.navigation {
  position: fixed;
  display: flex;
  align-items: center;
  height: 101px;
  width: 100%;
  padding: 0.5rem 0rem;
  background-color: white;
  color: white;
  box-shadow: 0 2px 2px 2px rgb(9 9 9 / 23%);
  z-index: 999;
  top: 0;

}

.header-0 {
  padding: 40px 0px;

}

.brand-name {
  text-decoration: none;
  color: white;
  font-size: 2rem;
  margin-left: 1rem;
  font-weight: 800;
}

.navigation-menu {
  margin-left: auto;
  margin-top: 16px;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
  margin-top: 3px
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
  color: #333130;
  font-size: 16px;
  font-weight: 700;


}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: #357997 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  background-color: #357997;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.dropdown{
  background-color: white!important;
  padding: 0!important;
}


.custom-dropdown-toggle{
  /* background-color: white!important; */
  border: none!important;
  padding: 0!important;
}

.dropdown:hover .dropdown-menu {
  display: block;
}


.dropdown-toggle::after {
  display: none !important;
}

.hamburger:hover {
  background-color: #357997;
}

@media screen and (max-width: 550px) {
  .hamburger {
    display: block;
  }

  .navigation-menu ul {
    flex-direction: column;
    position: absolute;
    top: 72px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: white;
    border-top: 1px solid #878787;
    display: none;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
  }

  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 10px 0;
  }

  .navigation-menu li:hover {
    background-color: #357997;
  }

  .navigation-menu.expanded ul {
    display: block;
  }

  .navigation-menu li a {
    color: white;
    width: 100%;
    padding: 10px 0;
  }

  .navigation-menu.expanded ul {
    display: block;
    height: 250px;
    background-color: #878787;
  }
}

.dropdown-content {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust spacing between text and icon */
}

/* .roy{
    animation: spin 9s infinite linear;
    
  }
   @keyframes spin{
    0% {transform: rotate((0deg));}
    100% {transform: rotate(360deg);}
   } */